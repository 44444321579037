import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SyncMonitorTopTabs from '../syncMonitor/SyncMonitorTobTabs';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const arr = Object.entries(children)?.map((e) => ({ [e[0]]: e[1] }));
    console.log('arr ', arr)
    console.log('props in tabpannel ', props);
    const cdpDates = Object.keys(children[props?.systemName]?.cdp);
    console.log('cdpDates ', cdpDates)
    let datecategories = []
  



    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{width:"100%"}}
        >
            {value === index && (
                <Box sx={{  }} style={{ float:"center" }}>
                    <SyncMonitorTopTabs
                        children={children} systemName={props?.systemName} />
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    console.log('index ', index)
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function SyncMonitorLeftPannel(props) {


    const [value, setValue] = React.useState(0);
    console.log('props in syncmonitorpannel ', props);
    console.log('props count ', props?.categories, props?.dataset)
    const arrayOfObj = Object.entries(props?.countResponse?.stats).map((e) => ({ [e[0]]: e[1] }));
    console.log('arrayOfObj ', arrayOfObj)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: "100%", width: 1500 }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' ,paddingTop:"60px"}}
            >
                {
                    props?.systems?.map((system, ind) => { return <Tab label={system} {...a11yProps(ind)} /> }
                    )

                }
            </Tabs>
            {
                arrayOfObj?.map((systemObj, ind) => {
                    return <TabPanel systemName={Object?.keys(systemObj)[0]} value={value} index={ind}
                        type='accounts' >
                        {systemObj}
                    </TabPanel>
                })
            }

        </Box>
    );
}
