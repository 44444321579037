import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation, HashRouter as Router } from "react-router-dom";
import './index.css';
// import MyComponent from './Components/dashboard';
import reportWebVitals from './reportWebVitals';

// import CacheBuster from 'react-cache-buster';
// import { version } from '../package.json';
import loader from "./loader.gif"

import SyncMonitorParent from './Components/syncMonitor/SyncMonitorParent';
import { AuthProvider } from "@bamboobox/b2logincheck";
import {ResourceCheck} from '@bamboobox/b2resource-check/dist'

// console.log("version of the current app",version)
function RApp() {

  return (
  //   <CacheBuster
  //   currentVersion={version}
  //   isEnabled={true} //If false, the library is disabled.
  //   isVerboseMode={true} //If true, the library writes verbose logs to console.
  // >
    <React.StrictMode>
     <SyncMonitorParent />
    </React.StrictMode>
  // </CacheBuster>
  );
}
const resources=["monitoring_module"]

ReactDOM.render(
  <AuthProvider loginURL={window._env_.LOGIN_URL} authcheckURL={window._env_.AUTH_URL}>
    <ResourceCheck component={<RApp />} resources={resources} />
    {/* <RApp /> */}
  </AuthProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();