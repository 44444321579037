import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ChartComponent from "./ChartComponent";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
var _ = require('lodash');


 function TabPanel(props) {
    const { children, value, index, ...other } = props;
    console.log('props in top tab 2nd comp ',props)
   



    const handlechange = (event) => {
        console.log('option sel ', event?.target?.value);
        props?.setSelectActivities(event?.target?.value);
    }



    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>

                    {
                        props?.type === 'activities' &&
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={props?.activitiesType}
                            onSelect={handlechange}
                            sx={{ width: 300 ,float:"right"}}
                            size="small"
                            renderInput={(params) => <TextField {...params} label={props?.selectActivities} placeholder="Activities" />}
                        />
                    }
                    <ChartComponent data={props?.dataset} type={props?.type} />


                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SyncMonitorTopTabs(props) {
    const [value, setValue] = React.useState(0);
    const [entities,setEntities ] = React.useState(['ACCOUNTS', 'CONTACTS', 'LEADS', 'OPPORTUNITY','ACTIVITIES', 'PROGRAMS', 'PROGRAM MEMBERSHIP']);
    const arr = [0, 1, 2, 3, 4, 5,6]

    console.log('props in tab ', props)
    const [selectActivities, setSelectActivities] = React.useState('');
    const [activitiesType, setActivityType] = React.useState([]);
    const [dataset , setData] = React.useState([]);

   

    useEffect(() => {
        console.log('value ',value)
        let data = []
        let activityType = []
            let valueString = ''
            let valueInt
            let systemName = props?.systemName;
            console.log('sys ', systemName, props?.children, props?.children[systemName])
            let Systemdata = props?.children[systemName];
            console.log(Systemdata);
            for (let obj in Systemdata) {
                console.log('obj in loop ', obj)
                for (let dateObj in Systemdata[obj]) {
                    let element = []
                    console.log('dateObj ', dateObj, ' ', Systemdata[obj][dateObj][_.camelCase(entities[value])]);
                    element.push(dateObj);
                    element.push(obj);
                    if (_.camelCase(entities[value]) === 'activities') {
                        for (let actObj in Systemdata[obj][dateObj][_.camelCase(entities[value])]) {
                            if(!activityType.includes(actObj))
                            activityType.push(actObj);
                        }
                        console.log('act type ', activityType)
                        setActivityType(activityType);
                        let activityObject =  Systemdata[obj][dateObj][_.camelCase(entities[value])];
                        let count = 0
                        for (let obj in activityObject) {
                            count += parseInt(activityObject[obj]?.added);
                        }
                        if (selectActivities !== ''){
                            valueInt = Systemdata[obj][dateObj]['activities'][selectActivities]?.added;
                        }
                        else{
                            valueInt = count;
                        }
        
                    } else {
                        valueString = Systemdata[obj][dateObj][_.camelCase(entities[value])]?.added;
                        valueInt = parseInt(valueString);
        
                    }
                    element.push(valueInt);
                    console.log('element ', element)
                    data.push(element);
                    
                }
        
            }
            setData(data);
    },[value,selectActivities,props])


   
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable"
               style={{ width:"1000"}}
>
                    {
                        entities.map((entity, index) => {
                            return <Tab label={entity} index={index} {...a11yProps(index)} >

                            </Tab>
                        })
                    }
                </Tabs>
            </Box>
            {
                arr?.map((ind) => {
                    return <TabPanel value={value} index={ind}
                        data={props?.data} type={_.camelCase(entities[value])} systemObj={props?.children} setSelectActivities = {setSelectActivities}

                        systemName={props?.systemName} selectActivities = {selectActivities} dataset = {dataset} activitiesType = {activitiesType}
                    ></TabPanel>
                })
            }
        </Box>
    );
}
