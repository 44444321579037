import React, { useState, useEffect } from "react";
import { Theme, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import DateRangePickerComponentContact from '../customComponentsContacts/DateRangePickerComponentContact';
import SyncMonitorParent from '../syncMonitor/SyncMonitorParent.css';
import { Padding } from "@mui/icons-material";
import SyncMonitorLeftPannel from '../syncMonitor/SyncMonitorLeftPannel';
import { _objectSpread2 } from "react-select/dist/index-ea9e225d.cjs.prod";
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartComponent from "./ChartComponent";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing * 2,
    textAlign: "center",
    height: "100%",


  }
}));


export default function BasicSelect() {
  const classes = useStyles();
  let daybeforeWeek = new Date();
  daybeforeWeek.setDate(daybeforeWeek.getDate() - 6)
  const [fromDate, setFromDate] = React.useState(daybeforeWeek)
  const [toDate, setToDate] = React.useState(new Date())
  const [tenantNames, setTenantNames] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [tenantMap, setTenantMap] = useState([]);
  // let values = [];
  // values.push(fromDate);
  // values.push(toDate);

  const [selectTenant, setSelectTenant] = useState('');
  const [systems, setSystems] = useState();
  const [countResponse, setCountResponse] = useState();

  const [attributeData, setAttributeData] = React.useState(
    { values: [fromDate, toDate] }
  );

  const getTenantId = () => {
    if (tenantMap.length > 0) {
      for (let obj of tenantMap) {
        if (obj?.tenantName === selectTenant) {
          return obj?.tenantId;
        }
      }
    }
    return null;

  }

  const fetchTenantNames = async () => {
    try{
      let url = `${window._env_.USER_MANAGEMENT_API}/tenant/get-all-tenant-names`;
      console.log('tenant fetch ', url)
      const tenantRequest = await fetch(
        url,{
          credentials: "include"
        }
      );
      const tenantResponse = await tenantRequest.json();
      const tenantIdWithName = tenantResponse.data;
      console.log('fetch ten ', tenantResponse)
      let tenantNameArr = []
      for (let obj of tenantIdWithName) {
        tenantNameArr.push(obj?.tenantName);
      }
      return [tenantIdWithName,tenantNameArr];

    }catch(e){
      console.log('Error occured in fetchTenantName ',e)
    }

  }

  const handlechange = (event) => {
    console.log('option sel ', event?.target?.value);
    setSelectTenant(event?.target?.value);
  }

  const fetchCounts = async () => {
    try{
      console.log('in fetchcounts ', tenantMap)
      let start = new Date(fromDate);
      let end = new Date(toDate);
      let systemArr = [];
      console.log(start, ' ', end);
      end = end.toISOString().split('T')[0];
      start = start.toISOString().split('T')[0];
      console.log(start, ' ', end);
      let tenantId = getTenantId();
      console.log(tenantId)
      let URL = `${window._env_.MONITORING_MODULE_BACKEND}/stats/summary/${tenantId}?startDate=${start}&endDate=${end}`
      console.log('monitoring url ', URL);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const countResponse = await fetch(URL, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      });
      console.log('response ', countResponse);
      const responseJSON = await countResponse.json();
  
      console.log('list of third party system ', responseJSON);
      if (responseJSON?.data?.stats)
        systemArr = Object.keys(responseJSON?.data?.stats)
      return [responseJSON?.data,systemArr]
     

    }catch(e){
      console.log(`Error in fetchcount`,e)
    }
  }

  useEffect( () => {
    setLoading(true);
    if (tenantNames?.length === 0) {
      fetchTenantNames().then(([tobjs,tnames])=>{
        setTenantMap(tobjs);
        setTenantNames(tnames);
        setSelectTenant(tnames?.[0] || '')
      }).catch(e => {
        console.error(`Error occurred in fetchTenantNames`,e);
        setTenantMap([]);
        setTenantNames([]);
        setSelectTenant('')
      })
    }
    console.log('tenant names are : ', tenantNames)
    if (selectTenant != '' && toDate != '' && fromDate != '') {
      console.log('selectTenant ', selectTenant)
      console.log('toDate ', toDate)
      console.log('fromDate ', fromDate)
      fetchCounts().then(([resp,systemArr])=>{
        setCountResponse(resp);
        setSystems(systemArr);
        setLoading(false);
      }).catch(e => {
        console.error(`Error occurred in fetchCounts`,e);
      })
    }
  }, [selectTenant, toDate, fromDate]);



  const onAttributeChange = (key, value) => {

  }






  return (

    <div class="sync-monitor-parent">


      <Paper variant="outlined" >
        <div class="header"><h4>Sync Monitor</h4></div>
        <div class="tenant-text-field">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={tenantNames}
            onSelect={handlechange}
            sx={{ width: 300 }}
            size="small"
            renderInput={(params) => <TextField {...params} label={selectTenant} placeholder="Tenant" />}
          />
        </div>
        <div class="date-range-picker">
          <DateRangePickerComponentContact toDate={toDate} setToDate={setToDate} fromDate={fromDate}
            setFromDate={setFromDate} style={{ width: "22vw", maxWidth: "18vw" }} attributeData={attributeData}
            onAttributeChange={onAttributeChange} />
        </div>
      </Paper>

      {
        !loading ?
          <Grid container spacing={0.5} style={
            { height: "100vh", width: "fit" }
          }>
            <>
            {systems.length > 0?
            <SyncMonitorLeftPannel systems={systems} countResponse={countResponse} 
              type='accounts' />:<>No Data Found For this Tenant</>}
            </>
           
          </Grid> : <CircularProgress />
      }
    </div>
  );
}
