
import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts";

ReactFC.fcRoot(FusionCharts, TimeSeries);


const schemaFetch = [
  {
    "name": "Time",
    "type": "date",
    "format": "%d-%b-%y"
  },
  {
    "name": "schema",
    "type": "string"
  },
  {
    "name": "Counts",
    "type": "number"
  }
]

const dataSource = {
  chart: {},
  caption: {
    text: "Stats"
  },
  subcaption: {
    text: ""
  },
  series: "schema",
  yaxis: [
    {
      plot: "Counts",
      title: "Counts",
      format: {
        prefix: ""
      }
    }
  ]
};

const ChartComponent = (props) => {
  console.log('props in chartcomp ',props)
  const [timeseriesDs, setTimeseriesDs] = useState({
    type: "timeseries",
    renderAt: "container",
    width: "1000",
    height: "500",
    dataSource
  });

  const onFetchData = (props) => {
    
    const data = props?.data;
    const schema = schemaFetch;
    const fusionTable = new FusionCharts.DataStore().createDataTable(
      data,
      schema
    );
    const temptimeseriesDs = Object.assign({}, timeseriesDs);
    temptimeseriesDs.dataSource.data = fusionTable;
    setTimeseriesDs({...temptimeseriesDs});
}

  useEffect(()=>{
    onFetchData(props);
  },[props])


    return (
      <div>
        {timeseriesDs.dataSource.data ? (
          <ReactFC {...timeseriesDs} />
        ) : (
          "loading"
        )}
      </div>
    );
}

export default ChartComponent;
