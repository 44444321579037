import React from "react";
import "date-fns";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "./DateRangePickerComponentContact.css";
import Button from "@material-ui/core/Button";
import Popover from '@mui/material/Popover';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiThemeProvider } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import UItheme from "@bamboobox/b2-theme"
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';

// const useStyles = makeStyles((theme) => ({
//   button: {
//       margin:"8px 0px 0px 0px !important",
//   },
// }));



function ContactDateRangePickerComponent(props) {
  console.log('pooja date',props?.toDate,' ',props?.fromDate);
  function convertDateRangeToFormattedString(oldDate, currentDate) {
    let monthArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var tempOldDate = convertUTCDateToLocalDate(oldDate)
    var tempCurrentDate = convertUTCDateToLocalDate(currentDate)

    let dateString = `${tempOldDate.getDate()} ${monthArray[tempOldDate.getMonth()]
      } ${tempOldDate.getFullYear().toString().substring(2, 4)} - ${tempCurrentDate.getDate()} ${monthArray[tempCurrentDate.getMonth()]
      } ${tempCurrentDate.getFullYear().toString().substring(2, 4)}`;
    console.log(
      "convertDateRangeToFormattedString",
      tempOldDate,
      tempCurrentDate,
      dateString
    );
    return dateString;
  }
  const frequencyValues = ["Weeks", "Months", "Quarters", "Years"];
  // const classes = useStyles();

  const [open, setOpen] = React.useState(false);
    
    const [currentDate, setCurrentDate] = React.useState((props?.attributeData?.values[1])?new Date(props.attributeData.values[1]) : new Date());
    const [oldDate, setOldDate] = React.useState((props?.attributeData?.values[0])?new Date(props.attributeData.values[0]):  new Date(Date.now() - 86400000));
    
  
    const [displayedDate, setDisplayedDate] = React.useState(
      convertDateRangeToFormattedString(oldDate, currentDate)
    );
    const [fromDateMax, setFromDateMax] = React.useState(new Date());
    const [fromDateMin, setFromDateMin] = React.useState(undefined);
    const [toDateMax, setToDateMax] = React.useState(new Date());
    const [toDateMin, setToDateMin] = React.useState(undefined);
  
    const [radioBtnValue, setRadioBtnValue] = React.useState("fixed");

    const [numberFieldvalue, setNumberFieldvalue] = React.useState("3");
    const [intervalValue, setIntervalValue] = React.useState("Weeks");
    const [prevOldDate, setPrevOldDate] = React.useState(new Date(oldDate))
    const [prevCurrentDate, setPrevCurrentDate] = React.useState(new Date(currentDate))
    const [prevIntervalValue, setPrevIntervalValue] = React.useState(intervalValue)
    const [prevNumberFieldValue, setPrevNumberFieldValue] = React.useState(numberFieldvalue);
    const [flag,setFlag] = React.useState(0)
    let setFromDate = props?.setFromDate;
    let setToDate = props?.setToDate;

    function convertUTCDateToLocalDate(date) {
      let offset = date.getTimezoneOffset()*60*1000
        if(offset>0){
            date.setHours(0)
            date.setMinutes(0)
            date = new Date(date)
        }
        else{
          date = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
      }
      // let timeInEpoch = date.getTime()
      // if((timeInEpoch - offset) > 0)
      return date;
    }

    React.useEffect(() => {
      if(flag==0 && currentDate && oldDate){
        var tempOldDate = new Date(oldDate)
        tempOldDate = convertUTCDateToLocalDate(tempOldDate) 
        var tempCurrentDate = new Date(currentDate)
        tempCurrentDate = convertUTCDateToLocalDate(tempCurrentDate)
        props.onAttributeChange([tempOldDate, tempCurrentDate], "values");
        setFlag(1)
      }
    })



  const handleRadioBtnChange = (event) => {
    console.log("changed radio button to ", event?.target?.value);
    setRadioBtnValue(event?.target?.value);
    let e = { target: { value: "" } }
    e.target.value = intervalValue;
    // handleIntervalChange(e);
  };

  const handleNormalBtnClick = (event) => {
    if (open) {
      setAnchorEl(event.currentTarget);
      setDisplayedDate(convertDateRangeToFormattedString(oldDate, currentDate));
      var fromDateValue = new Date(oldDate)
      fromDateValue = convertUTCDateToLocalDate(fromDateValue)

      var toDateValue = new Date(currentDate)
      toDateValue = convertUTCDateToLocalDate(toDateValue)
      setFromDate(fromDateValue);
      setToDate(toDateValue);
      // setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleDateFromChange = (value) => {
    let tempDate = value;
    tempDate = convertUTCDateToLocalDate(tempDate)
    setToDateMin(tempDate);

    setPrevOldDate(oldDate)
    setOldDate(new Date(value));
    
    let d1 = new Date(value);
    var tempCurrentDate = new Date(currentDate)
    tempCurrentDate = convertUTCDateToLocalDate(tempCurrentDate)
    props.onAttributeChange([tempDate, tempCurrentDate], "values");
    
    
    console.log(
      "handleDateFromChange ",
      value,
      new Date(value),
      tempDate,
      toDateMin
    );
};

  const handleDateToChange = ( value) => {

    setCurrentDate(new Date(value));
    var toDateValue = value
    toDateValue = convertUTCDateToLocalDate(toDateValue)
    setFromDateMax(toDateValue);
    var tempOldDate = new Date(oldDate)
    tempOldDate = convertUTCDateToLocalDate(tempOldDate)
    let d1 = new Date(value);
    props.onAttributeChange([tempOldDate, toDateValue], "values")
    
    console.log("handleDateToChange ", value, new Date(value), toDateValue, fromDateMin);

  };

  const handleClickAway = () => {
    console.log(
      "handleClickAway",
      convertDateRangeToFormattedString(oldDate, currentDate)
    );
    setOpen(false);

    setDisplayedDate(convertDateRangeToFormattedString(oldDate, currentDate));
    var tempOldDate = new Date(oldDate)
    tempOldDate = convertUTCDateToLocalDate(tempOldDate)
    var fromDateValue = convertUTCDateToLocalDate(new Date(Date.UTC(tempOldDate.getFullYear(), tempOldDate.getMonth(), tempOldDate.getDate())))
    // fromDateValue.setUTCDate(fromDateValue.getDate())
    // fromDateValue.setUTCHours(0, 0, 0, 0)

    var tempCurrentDate = new Date(currentDate)
    tempCurrentDate = convertUTCDateToLocalDate(tempCurrentDate)
    var toDateValue = convertUTCDateToLocalDate(new Date(Date.UTC(tempCurrentDate.getFullYear(), tempCurrentDate.getMonth(), tempCurrentDate.getDate())))
    // toDateValue.setUTCDate(toDateValue.getDate())
    // toDateValue.setUTCHours(0, 0, 0, 0)
    setFromDate(fromDateValue);
    setToDate(toDateValue);
  };

  const handleCancelBtn = () => {
    setOpen(false)
    setOldDate(convertUTCDateToLocalDate(prevOldDate));
    setCurrentDate(convertUTCDateToLocalDate(prevCurrentDate))
    setNumberFieldvalue(prevNumberFieldValue);
    setIntervalValue(prevIntervalValue)
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const popupOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <MuiThemeProvider theme={UItheme}>
      <ClickAwayListener onClickAway={() => { }}>
        <span  style={{width:"18vw", marginTop:"8px"}} >
          <Button onClick={handleNormalBtnClick}
            className=""
            variant="outlined"
            style={{display:"flex", justifyContent:"space-between",color:"#8A8A8A",borderColor:"#D2D2D2", height: "40px", width: "18vw", fontSize:"16px",textTransform: "none",}}
            
          >
            {displayedDate}
            <DateRangeRoundedIcon style={{fill: 'rgba(3, 3, 3, 0.6)', paddingRight:"4px"}}/>
          </Button>

          {open ? (
        <Popover style={{ borderRadius:"18px"}}
          open={popupOpen}
          onClose={handleClose}
          anchorEl={anchorEl}
        >
        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{padding:"8px"}}>
        <div style={{padding:"8px"}}>
                        From:
                        <br></br>
                        <KeyboardDatePicker
                            disableToolbar
                            minDate={fromDateMin}
                            maxDate={fromDateMax}
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            placeholder='DD/MM/YYYY'
                            InputProps={{
                                style: {
                                    fontSize: 16,
                                    height: 38,
                                    color:"rgba(3, 3, 3, 0.6)",
                                }
                            }}
                            style={{ width: '18vw'}}
                            value={oldDate}
                            autoOk={true}
                            onChange={handleDateFromChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
        </div>
        <div style={{padding:"8px"}}>
           
                        To:
                        <br></br>
                        <KeyboardDatePicker
                          disableToolbar
                          disabled={props?.disabled||false}
                          minDate={toDateMin}
                          maxDate={toDateMax}
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          placeholder='DD/MM/YYYY'
                          InputProps={{
                              style: {
                                  fontSize: 16,
                                  height: 38,
                                  color:"rgba(3, 3, 3, 0.6)",
                              }
                          }}
                          style={{ width: '18vw'}}
                          value={currentDate}
                          autoOk={true}
                          onChange={handleDateToChange}
                          KeyboardButtonProps={{
                              'aria-label': 'change date',
                          }}
                        />
              </div>
                      </MuiPickersUtilsProvider>
                    
        <div style={{padding:"8px",display: "flex", marginTop: "15px", alignContent: "center",  justifyContent: "flex-end"}}>
                <Button variant="outlined" color="primary" onClick={handleCancelBtn} style={{marginRight: "24px"}}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleClickAway}>
                  Apply
                </Button>
              </div>
            </Popover>
          ) : null}
        </span>
      </ClickAwayListener>
    </MuiThemeProvider>
  );
}

export default ContactDateRangePickerComponent;
